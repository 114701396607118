import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Links } from "./pages/Links";

export const AppRouter = () => {
    return <BrowserRouter>
        <div>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/:token" element={<Links />} />
            </Routes>
        </div>
    </BrowserRouter>
}