import "./Links.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Links = () => {
    
    const { token } = useParams();
    const [link, setLink] = useState({});
    const [viewStatus, setViewStatus] = useState("");
    const [viewContent, setViewContent] = useState("");

    useEffect(() => {
        getLink();
    }, []);

    useEffect(() => {
        getViewStatus();
    }, [link]);

    useEffect(() => {
        getViewContent();
    }, [viewStatus]);

    useEffect(() => {
        const interval = setInterval(() => {
            checkOpenedOrClosedDate();
        }, 60000);
        return () => clearInterval(interval);
    }, [viewStatus]);

    function getLink() {
        axios.get("https://api.alimentoparatualma.org/api/v1/link/" + token + "?by=token").then((response) => setLink(response.data.data)).catch((error) => setLink(error.response.data.data));
    }

    function getViewStatus() {
        let viewStatus = "loading";
        if (link.error !== undefined) {
            viewStatus = "error";
        } else if (link.id !== undefined) {
            if (link.status === "close") {
                viewStatus = "close";
            } else if (link.status === "open") {
                let dateStop = false;
                if (link.dateOpened !== null && link.dateClosed !== null) {
                    let dateToday = new Date();
                    let dateOpened = new Date(link.dateOpened);
                    let dateClosed = new Date(link.dateClosed);
                    if (dateToday < dateOpened) {
                        dateStop = true;
                        viewStatus = "preopen";
                    } else if (dateToday > dateClosed) {
                        dateStop = true;
                        viewStatus = "posopen";
                    }
                }
                if (dateStop === false) {
                    if (link.privacity === "private") {
                        let viewToken = localStorage.getItem("viewToken");
                        let viewPassword = localStorage.getItem("viewPassword");
                        if (viewToken === null || (viewToken !== null && viewToken !== token) || viewPassword === null || (viewPassword !== null && viewPassword === "password")) {
                            viewStatus = "password";
                        } else if (viewToken !== null && viewToken === token && viewPassword !== null && viewPassword === "player") {
                            viewStatus = "player";
                        }
                    } else if (link.privacity === "public") {
                        viewStatus = "player";
                    }
                }
            }
        }
        if (viewStatus != "loading") {
            if (viewStatus === "password" || viewStatus === "player") {
                localStorage.setItem("viewToken", token);
                localStorage.setItem("viewPassword", viewStatus);
            } else {
                localStorage.removeItem("viewToken");
                localStorage.removeItem("viewPassword");
            }
        }
        setViewStatus(viewStatus);
    }

    function getViewContent() {
        if (viewStatus === "loading") {
            setViewContent(
                <div className="loading">
                    <FontAwesomeIcon icon="fa-solid fa-spinner" />
                    <p>Buscando este link...</p>
                </div>);
        } else if (viewStatus === "error") {
            setViewContent(
                <div className="error">
                    <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
                    <p>Este link no existe.</p>
                </div>);
        } else if (viewStatus === "close") {
            setViewContent(
                <div className="close">
                    <FontAwesomeIcon icon="fa-solid fa-ban" />
                    <p>Este link no está disponible.</p>
                </div>);
        } else if (viewStatus === "preopen") {
            setViewContent(
                <div className="preopen">
                    <FontAwesomeIcon icon="fa-solid fa-clock" />
                    <p>Este link abre el <strong>{link.dateOpened.split(" ")[0]}</strong> a las <strong>{link.dateOpened.split(" ")[1]}</strong> hrs.</p>
                </div>);
        } else if (viewStatus === "posopen") {
            setViewContent(
                <div className="posopen">
                    <FontAwesomeIcon icon="fa-solid fa-lock" />
                    <p>Este link está cerrado.</p>
                </div>);
        } else if (viewStatus === "password") {
            setViewContent(
                <form className="password" onSubmit={checkPassword}>
                    <FontAwesomeIcon icon="fa-solid fa-lock" />
                    <input type="password" name="password" placeholder="Escribe la contraseña." />
                    <button>Desbloquear</button>
                </form>);
        } else if (viewStatus === "player") {
            setViewContent(
                <article className="player">
                    <header>
                        <p>"{link.preach.title}"</p>
                    </header>
                    <main>
                        {(link.preach.type === "video") ? <video src={link.preach.file} width="100%" controls /> : (link.preach.type === "audio") ? <audio src={link.preach.file} width="100%" controls /> : ""}
                    </main>
                    {(link.dateClosed !== null) ? <footer><p>Este link cierra el <strong>{link.dateClosed.split(" ")[0]}</strong> a las <strong>{link.dateClosed.split(" ")[1]}</strong> hrs.</p></footer> : ""}
                </article>
            );
        }
    }

    function checkPassword(event) {
        event.preventDefault();
        let password = event.target.password.value;
        if (password !== "") {
            if (password === link.password) {
                localStorage.setItem("viewPassword", "player");
                setViewStatus("player");
            } else {
                alert("La contraseña es incorrecta.");
                event.target.password.value = "";
            }
        } else {
            alert("Escribe la contraseña.");
        }
    }

    function checkOpenedOrClosedDate() {
        let dateToday = new Date();
        if (viewStatus === "preopen") {
            let dateOpened = new Date(link.dateOpened);
            if (dateToday >= dateOpened) {
                getViewStatus();
            }
        } else if (viewStatus === "password" || viewStatus === "player") {
            if (link.dateClosed !== null) {
                let dateClosed = new Date(link.dateClosed);
                if (dateToday > dateClosed) {
                    getViewStatus();
                }
            }
        }
    }

    return <section className="Links">{viewContent}</section>
}